<template>
  <span class="pe-3">
    <button
      class="btn btn-secondary btn-no-r btn-sm text-success"
      :class="{ 'bg-success text-white': modelValue == 1 }"
      :title="$t(`orderButtons.acceptOrder`)"
      v-on:click="accept"
      :disabled="modelValue !== null"
    >
      <BaseIcon name="tick-circle" />
    </button>
    <button
      class="btn btn-secondary btn-no-l btn-sm text-danger"
      :class="{ 'bg-danger text-white': modelValue == 0 }"
      :title="$t(`order.denyOrder`)"
      v-on:click="deny"
      :disabled="modelValue == 0 || extraRow > 1"
    >
      <BaseIcon name="close-circle" />
    </button>
  </span>
</template>
<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "OrderButtons",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
    extraRow: String,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    accept() {
      window.apps.callEvent("order:accept", this.id);
    },
    deny() {
      window.apps.callEvent("order:deny", this.id);
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
